import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription, throwError, timer } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthServices } from '../services/auth.service';

@Injectable()
export class HttpConfig implements HttpInterceptor {
  constructor(private _auth: AuthServices) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const appendURL = /http|https|.json/.test(req.url) ? req.url : `${environment.apiURL}/${req.url}`;
    return next
      .handle(
        req.clone({
          headers: this.appendHeaders(req.headers),
          url: appendURL,
        })
      )
      .pipe(map((c) => this.handleResponse(c)))
      .pipe(
        retry(1),
        catchError((c) => this.handleException(c))
      );
  }

  /** handle response data */
  private handleResponse(response: HttpEvent<any>): any {
    if (response instanceof HttpResponse) {
      if (response.body && response.body.refresh_token && response.body.access_token) {
        this._auth.setAccessToken({
          access_token: response.body.access_token,
          access_token_expire_date: response.body.access_token_expire_date,
          refresh_token: response.body.refresh_token,
          refresh_token_expire_date: response.body.refresh_token_expire_date,
        });
        delete response.body.refresh_token;
        delete response.body.refresh_token_expire_date;
        delete response.body.access_token;
        delete response.body.access_token_expire_date;
      }
    }
    return response;
  }

  /** append headers */
  private appendHeaders(headers: HttpHeaders) {
    if (this._auth.accessToken) {
      headers = headers.append('Authorization', 'Bearer ' + this._auth.accessToken.access_token);
    }
    return headers;
  }

  /** handle error exception */
  private handleException(res: HttpErrorResponse) {
    const _res: any = res;
    if (res.error && (res.error.message || res.error.Message)) {
      _res.message = res.error.message || res.error.Message;
    }
    // Unauthorization
    if (res.status == 401) this._auth.removeAccessToken();
    return throwError(() => _res);
  }
}
