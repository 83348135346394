import { Injectable } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { interval, Subject, Subscription, timer } from 'rxjs';
import { DateTimePickerOptions } from '../interfaces/event.interface';

/** การแจ้งเตื่อน */
@Injectable({ providedIn: 'root' })
export class AlertService {
  /** Loading data */
  private _loading: boolean = false;
  private _loadingSubscribe?: Subscription;
  private _loadingTimeout: number = 60; // 60 seconds
  private _popupEvent?: Subscription;
  private _calendar = {
    visible: false,
    event: new Subject(),
    subscribe: new Subscription(),
    options: new DateTimePickerOptions(),
  };

  /** List of summary message */
  private notifySummaries = {
    success: 'แจ้งเตือนสำเร็จ',
    info: 'แจ้งเตือนจากระบบ',
    warn: 'แจ้งเตือนให้ตรวจสอบ',
    error: 'แจ้งเตือนข้อผิดพลาด',
  };

  constructor(private _message: MessageService, private _confirm: ConfirmationService) {}

  /** datetimepicker */
  get __calendar__() {
    return this._calendar;
  }

  /** โหลดข้อมูล */
  get __getloading__() {
    return this._loading;
  }

  /** เด้ง Dialog โหลดข้อมูลขึ้นมากั้นหน้าเว็บ */
  loading(status: boolean) {
    timer(1000).subscribe(() => {
      this._loading = status;
      if (this._loading)
        this._loadingSubscribe = timer(this._loadingTimeout * 1000).subscribe(() => {
          if (this._loading) this.notify('โหลดข้อมูลนานเกินไป', 'info');
          this._loading = false;
          this._loadingSubscribe?.unsubscribe();
        });
      else this._loadingSubscribe?.unsubscribe();
    });
  }

  /** แจ้งเตือนธรรมดา */
  notify(message: string, severity: 'success' | 'info' | 'warn' | 'error' = 'error') {
    this._message.add({
      summary: this.notifySummaries[severity],
      severity: severity,
      detail: this._convertLanguage(message, severity),
    });
  }

  /** แจ้งเตือนยืนยันการทำรายการต่อไป */
  confirm(message: string, header: string = 'ต้องการทำรายการต่อไป ?') {
    return new Promise((resolve) => {
      this._confirm.confirm({
        key: 'confirm',
        header: header,
        acceptLabel: 'ต้องการ',
        rejectLabel: 'ไม่ต้องการ',
        message: message,
        acceptButtonStyleClass: 'p-button-sm p-button-rounded',
        rejectButtonStyleClass: 'p-button-sm p-button-rounded',
        accept: () => resolve(true),
        reject: () => resolve(false),
      });
    });
  }

  /** แจ้งเตือนจากระบบ dialog */
  dialog(message: string, header: string = 'แจ้งเตือนจากระบบ') {
    return new Promise((resolve) => {
      this._confirm.confirm({
        key: 'dialog',
        header: header,
        acceptLabel: 'ปิดหน้านี้',
        message: message,
        dismissableMask: true,
        accept: () => resolve(true),
      });
    });
  }

  /** เปิดหน้า datetime picker */
  datetimePicker(options?: DateTimePickerOptions) {
    if (options) this._calendar.options = Object.assign(this._calendar.options, options);
    this._calendar.subscribe?.unsubscribe();
    this._calendar.visible = true;
    return new Promise<any>(
      (resolve) =>
        (this._calendar.subscribe = this._calendar.event.subscribe((value) => {
          this._calendar.visible = false;
          resolve(value);
        }))
    );
  }

  /** สำหรับแปลงค่าข้อความ */
  private _convertLanguage(message: string, type: 'success' | 'info' | 'warn' | 'error' = 'error') {
    const pattern = /[\u0E00-\u0E7F]/;
    if (!pattern.test(message)) {
      console.warn(message);
      if (type == 'info' || type == 'success') return this.notifySummaries[type];
      return 'เกิดข้อผิดพลาด ไม่สามารถทำรายการได้';
    }
    return message;
  }

  /** เปิดหน้า Popup window ใหม่ */
  windowPopup({ url, title, w, h }: { url: string; title: string; w: number; h: number }, onClose?: Function) {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;
    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow: Window & { closePopup: Function } = window.open(
      url,
      title,
      `scrollbars=yes, width=${w / systemZoom}, height=${h / systemZoom}, top=${top}, left=${left}`
    ) as any;
    if (newWindow) {
      if (typeof window.focus === 'function') newWindow.focus();
      this._popupEvent?.unsubscribe();
      this._popupEvent = interval(300).subscribe(() => {
        if (!newWindow.closed) return;
        const _window: any = window;
        if (onClose) onClose(_window.popupParams);
        this._popupEvent?.unsubscribe();
        delete _window.popupParams;
      });
    }
    return newWindow;
  }

  /** error text */
  convertErrorMsg(err: any) {
    switch (err) {
      case 'NOT_SET_PIN':
        return '"ยังไม่ระบุ PIN"';

      case 'ACCOUNT_BLOCKED':
        return 'บัญชีนี้ถูกระงับ';

      case 'ACCOUNT_NOT_FOUND':
        return 'ไม่พบผู้ใช้งาน โปรดสมัครสมาชิก';

      case 'CUSTOMER_NOT_FOUND':
        return 'ไม่พบผู้ใช้งาน';

      case 'ACCOUNT_ALREADY_EXISTS':
        return 'บัญชีนี้ถูกใช้งานแล้ว';

      case 'ACCOUNT_INVALID_BY_PROVIDER':
        return 'การเชื่อมต่อบัญชีมีปัญหา โปรดล๊อกอินอีกครั้ง';

      case 'PHONE_NUMBER_DON_T_MATCH':
        return 'เบอร์โทรศัพท์ไม่ตรงกัน';

      case 'THIS_ACCOUNT_IS_CURRENTLY_IN_USE':
        return 'บัญชีนี้กำลังใช้งานอยู่';

      case 'INVALID_PROVIDER_TYPE':
        return 'เชื่อมต่อบัญชีต้นทางผิดประเภท';

      case 'STATUS_INVALID':
        return 'สถานะผิดพลาด';

      case 'ID_INVALID':
        return 'รหัสนี้ไม่ถูกต้องหรือไม่รองรับ';

      case 'INVALID_OTP':
        return 'OTP ไม่ถูกต้อง';

      case 'NOT_ALLOWED':
        return 'OTP ที่ขอไปก่อนหน้ายังไม่หมดอายุ\nโปรดทำรายการใหม่';

      case 'Wait Expiration Date':
        return 'ไม่อนุญาติให้ทำรายการ\nหรือคุณทำรายการซ้ำ';

      case 'USERNAME_OR_PASSWORD_INVALID':
        return 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง';

      case 'API_SERVER_DOWN':
        return 'กำลังปรับปรุ่งเซอร์เวอร์ ไม่สามารถใช้งานได้ในขณะนี้ ขออภัยในความไม่สะดวก';

      case 'NOT_FOUND_DATA':
        return 'ไม่พบข้อมูลที่ระบุ';

      case 'NOT_FOUND_VOUCHER':
        return 'ไม่พบโค้ดส่วนลดนี้';

      case 'OUT_OF_QUOTA':
        return 'รายการนี้มีการใช้งานครบแล้ว';

      case 'WRONG_INFORMATION':
        return 'ข้อมูลไม่ถูกต้อง';

      case 'DOOR_NOT_AVAILABLE':
        return 'ประตูไม่พร้อมใช้งาน';

      case 'ALREADY_AVAILABLE':
        return 'เปิดใช้งานไปแล้ว';

      default:
        return 'เกิดข้อผิดพลาดบางอย่าง';
    }
  }
}
