import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { AlertService } from './services/alert.service';
import { AuthServices } from './services/auth.service';
import { MasterDataService } from './services/master-data.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AlertService]
})
export class AppComponent implements OnInit {
  title = 'webapp-space';

  constructor(
    private primengConfig: PrimeNGConfig,
    private _alert: AlertService,
    private _auth: AuthServices,
  ) { }

  ngOnInit() {
    this._auth.initializeScheduleLoadToken();
    this.primengConfig.ripple = true;

    

  }

  get loading() {
    return this._alert.__getloading__;
  }



}
