import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import * as moment from 'moment';
declare const liff: any;

@Injectable({ providedIn: 'root' })
export class AuthServices {
  constructor(private _http: HttpClient) {
    this._loadAccessToken();
  }

  /** เก็บข้อมูล key ของ User Login */
  private _accessTokenKey = '___ssid___';

  /** ข้อมูล access token ไว้ยืนยันตัวตน */
  private _accessToken?: IAcccessToken;

  /** แปลงข้อมูล token และ ทำการต่ออายุ Token อัตโนมัติ */
  private _accessTokenSubscription?: Subscription;

  /** กำหนด token ไว้ในระบบ */
  setAccessToken(token: IAcccessToken): void {
    try {
      this._accessToken = token;
      const accessTokenEncrypt = window.btoa(encodeURIComponent(JSON.stringify(token)));
      localStorage.setItem(this._accessTokenKey, accessTokenEncrypt);
      this.initializeScheduleLoadToken();
    } catch (ex) {
      console.warn(ex);
    }
  }

  /** ลบ token ออกจากระบบ */
  removeAccessToken() {
    this._accessToken = undefined;
    this.logoutLine();
    localStorage.removeItem(this._accessTokenKey);
    if (this._accessTokenSubscription) this._accessTokenSubscription.unsubscribe();
  }

  /** ดึงข้อมูล token */
  get accessToken(): IAcccessToken | undefined {
    const token = localStorage.getItem(this._accessTokenKey);
    if (!token) this._accessToken = undefined;

    return this._accessToken;
  }

  /** ใส่ค่า access token ไว้ที่ตัวแปร _accessToken */
  private async _loadAccessToken() {
    try {
      this._accessToken = undefined;
      const token = localStorage.getItem(this._accessTokenKey);
      if (token) this._accessToken = JSON.parse(decodeURIComponent(window.atob(token)));
    } catch {}
  }

  initializeScheduleLoadToken() {
    this._loadAccessToken();
    if (!this.accessToken) return;
    const { access_token_expire_date } = this.accessToken;
    const expires = moment(access_token_expire_date).add(-3, 'minutes');
    const duration = moment.duration(expires.diff(moment()));
    const countdownTimeout = duration.asMilliseconds();
    this._accessTokenSubscription?.unsubscribe;
    this._accessTokenSubscription = timer(countdownTimeout).subscribe(() => {
      this._initalizeLoadRefreshToken();
      this._accessTokenSubscription?.unsubscribe();
    });
  }

  private _initalizeLoadRefreshToken() {
    if (!this.accessToken) return;
    const { refresh_token } = this.accessToken;
    this._http
      .post('api/MSAuthentication/profile', {
        refresh_token: refresh_token,
        req_new_refresh_token: true,
        req_new_access_token: true,
      })
      .subscribe();
  }

  logoutLine() {
    liff
      .init({
        liffId: '1657314320-DR3NV4En', // Use own liffId
      })
      .then(() => {
        if (liff.isLoggedIn()) {
          liff.logout();
        }
      });
  }
}

export interface IAcccessToken {
  access_token: string;
  access_token_expire_date: string;
  refresh_token: string;
  refresh_token_expire_date: string;
}
