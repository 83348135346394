<p-toast  position="bottom-center" class="z-[99999]"></p-toast>
<p-confirmDialog key="confirm" [style]="{ width: '320px' }" [baseZIndex]="9999999"></p-confirmDialog>
<p-confirmDialog #dl key="dialog" [style]="{ width: '320px' }" [baseZIndex]="10000">
  <ng-template pTemplate="footer">
    <button pButton type="button" class="p-button-sm p-button-rounded" icon="pi pi-check" [label]="dl.acceptButtonLabel"
      (click)="dl.accept()"></button>
  </ng-template>
</p-confirmDialog>
<div class="bg-bg1 desktop:w-[412px] laptop:w-[412px] tablet:w-[412px] mx-auto h-full min-h-[931px]">
  <router-outlet></router-outlet>
</div>
<app-loading *ngIf="loading"></app-loading>
