import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthServices } from '../services/auth.service';
// import { AppConfig } from '../configs/app.config';
// import { AuthService } from '../services/auth.service';
// import { DataService } from '../services/data.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private _auth: AuthServices,
    private _router: Router // private _data: DataService,
  ) {
    // super();
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this._CanActivate(childRoute, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._CanActivate(route, state);
  }

  private _CanActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> | boolean {
    if (this._auth.accessToken) return true;
    return this._router.navigate(['/', 'unAuthen', 'singin', 'singin', { _: state.url }]);
  }
}
