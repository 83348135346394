import { Component, OnInit } from '@angular/core';
declare const rive: any;

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    this.onRive();
  }

  async onRive() {
    const foo = new rive.Rive({
      src: 'assets/riv/01.riv',
      canvas: document.getElementById('canvas'),
      autoplay: true,
    });
  }
}
