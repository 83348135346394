export interface IAuthEvent {
    type?: AuthType;
    data?: any;
}

export enum AuthType {
    BRANCH = 1,
}

export class DateTimePickerOptions {
    value?: Date | Date[] = undefined;
    selectionMode?: 'single' | 'multiple' | 'range' = 'single';
    showTime?: boolean = false;
    showDate?: boolean = true;
    stepMinute?: number = 1;
    stepHour?: number = 1;
    eventBubble?: boolean = false;
}