import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppURL } from './app.url';
import { AuthGuard } from './guards/auth.guard';
import { AuthenURL } from './modules/authen/authen.url';
import { unAuthenURL } from './modules/unauthen/unauthen.url';

const routes: Routes = [
  {
    path: '',
    redirectTo: AppURL.unAuthen + '/' + unAuthenURL.home,
    pathMatch: 'full',
  },
  {
    path: AppURL.authen,
    loadChildren: () =>
      import('./modules/authen/authen.module').then((m) => m.AuthenModule),canActivateChild: [AuthGuard]
  },
  {
    path: AppURL.unAuthen,
    loadChildren: () =>
      import('./modules/unauthen/unauthen.module').then((m) => m.UnautenModule),
  },

  // { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
